@use 'common' as common;

.shortcuts {
    @include common.full-width-mixin();
    padding-top: 2.75rem;
    padding-bottom: 2.75rem;

    @media #{common.$mq-screen-mobile} {
        padding-top: 1.75rem;
        padding-bottom: 1.75rem;
    }
}

.person {
    background-color: var(--bg-color, var(--a-red-50));
}
.employer {
    background-color: var(--bg-color, var(--a-deepblue-100));
}
.provider {
    background-color: var(--bg-color, var(--a-white));
}
.other {
    background-color: var(--bg-color, var(--a-gray-100));
}

.header {
    margin-bottom: 1.5rem;

    @media #{common.$mq-screen-mobile} {
        margin-bottom: 1rem;
    }
}

.list {
    @include common.grid-layout-mixin(2);
    list-style: none;
    position: relative;
    padding: 0;

    &.threeCols {
        @include common.grid-layout-mixin(3);
    }

    li {
        display: flex;
    }
}

.item {
    transition: all 0.3s;
    background-color: var(--item-color, var(--a-white));
    width: 100%;
}

.item_person {
    &:hover,
    &:focus-within {
        background-color: var(--hover-color, var(--a-red-100));
    }
}

.item_employer {
    &:hover,
    &:focus-within {
        background-color: var(--hover-color, var(--a-deepblue-50));
    }
}

.item_provider {
    :global(.back) {
        svg {
            path,
            rect,
            circle {
                fill: var(--bg-color, var(--a-red-100));
            }
        }
    }
    &:hover,
    &:focus-within {
        background-color: var(--hover-color, var(--a-red-100));
        :global(.back) {
            svg {
                path,
                rect,
                circle {
                    fill: var(--bg-color, var(--a-white));
                }
            }
        }
    }
}
