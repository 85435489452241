.calculator {
    margin-bottom: 1.75rem;
}

.title {
    margin-bottom: 0.75rem;
}

.calculateButton {
    color: var(--default-action-color);
    box-shadow: inset 0 0 0 2px var(--default-action-color);
    margin-top: 1rem;
}

.calculateIcon {
    width: 1.5rem;
    height: 1.5rem;

    path {
        fill: var(--default-action-color);
    }
}
