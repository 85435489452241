@use '../../AreaCardGraphicsCommon.module' as areaGraphics;
@use '../../../AreaCard.module' as areaCard;

.shapes {
    @include areaCard.transitionMixin;
    position: absolute;
    right: 16px;
    top: 126px;
}

@include areaGraphics.onExpandMixin('.shapes') {
    right: 206px;
}

.letterPartOrange {
    background-color: var(--a-orange-200);

    @include areaCard.transitionMixin;
    position: absolute;
    width: 50.16px;
    height: 120px;
    right: -39px;
    top: 0;
    transform: skewX(-22.5914deg);
}

@include areaGraphics.onExpandMixin('.letterPartOrange') {
    right: 25px;
}

.letterPartBlue {
    background-color: var(--a-deepblue-200);

    @include areaCard.transitionMixin;
    position: absolute;
    width: 48px;
    height: 160px;
    right: -37px;
    top: 0;
}

@include areaGraphics.onExpandMixin('.letterPartBlue') {
    right: 0;
}

.letterPartBlueTwo {
    background-color: var(--a-deepblue-200);

    @include areaCard.transitionMixin;
    position: absolute;
    width: 50.16px;
    height: 120px;
    right: -26px;
    top: 0;
    transform: skewX(22.5914deg);
}

@include areaGraphics.onExpandMixin('.letterPartBlueTwo') {
    right: 75px;
}

.mask {
    background-color: var(--a-deepblue-100);

    @include areaCard.transitionMixin;
    position: absolute;
    width: 193.56px;
    height: 499px;
    right: -42px;
    top: -100px;
    transform: skewX(-22.09deg);
}

@include areaGraphics.onExpandMixin('.mask') {
    background-color: #ffb8b8;
    width: 48px;
    height: 160px;
    right: 102px;
    top: 0;
    transform: skewX(0);
}

.letter {
    @include areaCard.transitionMixin;
    position: absolute;
    right: 57px;
    top: 44px;
}

@include areaGraphics.onExpandMixin('.letter') {
    right: 102px;
    top: 88px;
}
