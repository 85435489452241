.wrapper {
    margin-bottom: 2rem;
}

.button {
    width: 100%;
    justify-content: flex-start;
    align-items: center;
    gap: var(--a-spacing-3);
    padding: var(--a-spacing-1);

    :global(.navds-button__icon) {
        margin: 0;
    }
}

.posterWrapper {
    display: flex;
    position: relative;
    width: 124px;
}

.playBadge {
    /* Set up the circle background */
    background-color: var(--default-action-color);
    border-radius: 50%;
    height: 40px;
    width: 40px;
    position: absolute;

    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;

    /* Add some basic centering and positioning */
    display: block;
}

.playLoader {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;

    :global(.navds-loader__background) {
        stroke: white !important;
    }
}

.playArrow {
    position: absolute;
    left: 4px;
    right: 0;
    top: 0;
    width: 14px;
    height: 18px;
    bottom: 0;
    margin: auto;
}

.macroVideo {
    :global(.gobrain-poster) {
        //hindrer "flash" av forhåndsvisningsbilde før videoen starter
        display: none;
    }

    & > div {
        //styling fra Qbrick doc: https://video.qbrick.com/docs/api/#examples.play2-script-adaptive
        padding-bottom: 56.25%; /* 16:9 aspect ratio */
        position: relative !important;
        height: 100% !important;
        width: 100% !important;

        & > div:first-of-type {
            bottom: 0;
            left: 0;
            position: absolute !important;
            right: 0;
            top: 0;
            height: 100% !important;
            width: 100% !important;
        }
    }
}

.previewImage {
    border-radius: var(--a-border-radius-small);
    display: block;
    max-width: 100%;
    min-height: 74px; // Keep video height in golden ratio rather than 16:9
    object-fit: cover;
}

.text {
    color: var(--a-text-default);
    margin-bottom: 0 !important; //overstyr kanskje litt for generell regel i MainArticlePermanent?
    text-align: left;
    line-height: 1.5;
}

.videoLength {
    padding-top: 0.25rem;
    font-weight: 400;
    color: var(--a-text-subtle);
    width: fit-content;
}

.hidden {
    display: none;
}
