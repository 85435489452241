.container {
    width: 100%;
    margin-bottom: 1.5rem;
}

.header {
    padding: 0;
    margin: 0 auto 0.5rem auto;
}

.subHeader {
    margin-bottom: 1.5rem;
    color: var(--a-gray-700);
}
