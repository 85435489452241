@use 'common' as common;

.container {
    :global(.layout__main) {
        display: flex;
        flex-direction: row;
        align-items: stretch;

        max-width: 63.5rem;
        width: 100%;

        margin-left: auto;
        margin-right: auto;
        padding-top: 2.25rem;
        padding-bottom: 3rem;

        @media #{common.$mq-screen-mobile} {
            flex-direction: column;
        }
    }

    :global(.region__first) {
        flex: 2;

        @media #{common.$mq-screen-tablet-and-desktop} {
            &:not(:only-child) {
                margin-right: 1.5rem;
            }
        }

        &:last-child {
            padding-bottom: 3rem;
        }
    }

    :global(.region__second) {
        flex: 1;

        @media #{common.$mq-screen-mobile} {
            margin-top: 2rem;
        }
    }
}
