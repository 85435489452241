@use 'common' as common;

.wrapper {
    background-color: common.$a-white;

    @include common.full-width-mixin();

    @media #{common.$mq-screen-mobile} {
        padding-bottom: 1.5rem;
    }
}

.person {
    padding-top: 1.75rem;
    padding-bottom: 2.75rem;

    @media #{common.$mq-screen-mobile} {
        padding-top: 1.25rem;
    }
}

.employer {
    padding-top: 3.5rem;
    padding-bottom: 4.25rem;

    @media #{common.$mq-screen-mobile} {
        padding-top: 2rem;
        padding-bottom: 2.25rem;
    }
}
.provider {
    padding-top: 2.5rem;
    padding-bottom: 4.5rem;

    @media #{common.$mq-screen-mobile} {
        padding-top: 2.25rem;
        padding-bottom: 3.25rem;
    }
}

.header {
    margin-bottom: 1.5rem;
}

.cards {
    @include common.unstyled-list();

    &.twocols {
        @include common.grid-layout-mixin(2);
    }
    &.threecols {
        @include common.grid-layout-mixin(3);
    }
}
