@use 'common' as common;

.formDetails {
    margin-bottom: var(--a-spacing-9);

    ul {
        list-style: disc;
        margin-bottom: 1.75rem;
    }

    li {
        margin-bottom: 0.25rem;

        &:last-child {
            margin-bottom: 0;
        }
        & > * {
            margin: 0;
        }
    }

    h3 {
        margin-bottom: 0.375rem;
    }

    // pull lists upward to text above if any.
    :global(.navds-body-long + ul),
    :global(.navds-body-long + ol) {
        margin-top: -1rem;
    }
}

.variation {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: var(--a-spacing-2);

    @media #{common.$mq-screen-mobile-small} {
        flex-direction: column;
    }
}

.ingress {
    & > :last-child {
        margin-bottom: 0;
    }
    &:not(:last-child) {
        margin-bottom: 1rem;
    }
}

.formNumbers {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 0.125rem;
    margin-bottom: 1rem;

    & > * {
        padding: 0.0625rem 0.3125rem;
        white-space: nowrap;
        color: var(--a-gray-700);

        &:first-child:not(.highlight) {
            padding-left: 0;
        }
    }
}

.highlight {
    background-color: var(--a-lightblue-200);
    color: var(--a-gray-800);
    border-radius: var(--a-border-radius-medium);
}

.separator {
    color: var(--a-border-default);

    @media #{common.$mq-screen-mobile} {
        display: none;
    }
}
