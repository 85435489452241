@use 'common' as common;

.publishingCalendar {
    padding: 2rem;
    background: common.$a-white;

    h1 {
        margin-bottom: 1rem;
    }

    .ingress {
        margin-bottom: 1rem;
    }

    header {
        margin-bottom: 1rem;
    }

    table {
        th {
            .dateHeader {
                display: flex;
                justify-content: center;
            }
        }

        td {
            .dateCell {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                width: 4.5rem;
                height: 4.5rem;
                font-weight: 600;
                padding: 1rem;
                border-radius: 50%;
                background-color: #d1d1d1;
            }

            .dateInfo {
                text-transform: uppercase;
            }
        }
    }
}
