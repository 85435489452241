@use 'common' as common;

.mobile {
    @media #{common.$mq-screen-desktop} {
        display: none;
    }
}

.mobileFilters {
    display: grid;
    column-gap: 0.75rem;
    height: 3rem;
    margin-top: 0.5rem;

    & > * {
        height: 100%;
    }

    &.withToggleFilters {
        grid-template-columns: 6rem 1fr;
    }
}

.mobileFilterButton {
    background-color: common.$a-deepblue-100;
    color: common.$a-text-default;

    :global(.navds-label) {
        font-weight: normal;
    }

    &:hover {
        background-color: common.$a-deepblue-500;
        color: common.$a-white;
    }
}

.mobileToggleFilters {
    overflow: hidden;
    visibility: hidden;
    opacity: 0;
    max-height: 0;

    transition-duration: 0.25s;
    transition-timing-function: ease-out;
    transition-property: padding, opacity, visibility, max-height;

    &.open {
        visibility: visible;
        opacity: 1;
        max-height: 30rem;
    }

    & > * {
        margin-top: var(--a-spacing-8, 2rem);
    }
}

.desktop {
    display: none;

    & > :not(:last-child) {
        margin-bottom: var(--a-spacing-8, 2rem);
    }

    @media #{common.$mq-screen-desktop} {
        display: initial;
    }
}
