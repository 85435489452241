@use 'common' as common;

$verticalPadding: 0.75rem;
$horizontalPadding: 0.5rem;
$decorWidth: 0.5rem;

.mainArticleChapterNavigation {
    background-color: white;
    border-radius: 0.28rem;
    display: flex;
    flex-direction: column;
    margin: 0 0 1.5rem 0;
    padding: 1rem 0;
    width: 100%;
    transition: all 0.3s linear;

    .title {
        padding: 1rem;
    }

    ul {
        @include common.unstyled-list();
        width: 100%;

        .item {
            display: block;
            font-size: 1.125rem;
            margin: 0 $decorWidth;
            padding: $verticalPadding $horizontalPadding;
            position: relative;
            text-decoration: none;
            z-index: 1;
            font-weight: common.$a-font-weight-bold;

            &:before {
                content: ' ';
                display: inline-block;
                height: 100%;
                left: -($decorWidth);
                position: absolute;
                top: 0;
                width: $decorWidth;
            }

            &.active {
                &:before {
                    background-color: var(--default-action-color);
                    content: '';
                    display: inline-block;
                    height: 100%;
                    left: -($decorWidth);
                    position: absolute;
                    top: 0;
                    width: $decorWidth;
                }
            }
        }

        a {
            &:hover {
                background-color: common.$a-blue-100;
                color: common.$a-gray-900;
                text-decoration: none;
            }
            &:focus {
                background: none;
                box-shadow: 0 0 0 0.2rem common.$a-border-focus;
                color: var(--default-action-color);
                cursor: pointer;
                outline: none;

                &:before {
                    left: 0;
                }
            }
        }
    }
}
