.officeInformation {
    margin: 2.5rem 0 4rem;
}

.expansionCardHeader {
    color: var(--default-action-color);
}

.expansionCardContent {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}
