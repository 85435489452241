.versionHistory {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 1rem;

    :global(.chevron) {
        margin-left: 0.5rem;
    }

    .spinnerContainer {
        margin-top: 1rem;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}
