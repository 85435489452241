@use 'common' as common;

.situationPage {
    display: flex;
    flex-direction: column;

    @media #{common.$mq-screen-mobile} {
        display: block;
        margin: 0rem 0.5rem;
        width: calc(100% - 1rem);
    }

    :global(.region__pageContent) {
        display: flex;
        flex-direction: column;
    }

    .content {
        margin-top: 4rem;
        display: flex;
        justify-content: flex-start;
        align-self: center;
        width: 100%;
        max-width: 40rem;

        & > * {
            width: 100%;
        }
    }

    .pullUp {
        margin-top: 0;
    }
}
