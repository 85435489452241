@use 'common' as common;

.contactInformationPage {
    display: flex;
    flex-direction: column;
}

.content {
    background-color: common.$a-white;
    padding: 2.5rem;
    width: 37.5rem;
}
.editorAlert {
    margin-bottom: 2rem;
}
