.tableWrapper {
    overflow-x: auto;

    // Content Studio tends to insert p-tags everywhere, which adds unwanted margins in table cells
    p:last-child {
        margin-bottom: 0;
    }

    &:not(:last-child) {
        margin-bottom: 1.5rem;
    }
}
