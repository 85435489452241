@use 'common' as common;

.largeTablePage {
    font-size: common.$a-font-size-medium;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    overflow-x: auto;

    h1 {
        font-size: common.$a-font-size-heading-medium;
        margin-bottom: 1rem;
        &:not(:first-child) {
            margin-top: 2rem;
        }
    }

    table {
        border-collapse: collapse;
        &:not(:last-child) {
            margin-bottom: 1rem;
        }
    }

    th {
        font-weight: common.$a-font-weight-bold;
        padding: 0.5rem 0.25rem;
        text-align: left;
    }

    thead {
        tr {
            border-bottom: 1px common.$a-gray-200 solid;
        }
    }

    tbody {
        tr:first-child {
            font-weight: common.$a-font-weight-bold;
            td {
                padding-top: 0.5rem;
            }
        }

        tr:nth-of-type(even) {
            background-color: common.$a-blue-100;
        }

        td {
            white-space: nowrap;
        }
    }

    :global(.statPage) {
        display: flex;
        flex-direction: column;
        background-color: white;
        padding: 1rem;
    }

    :global(.statHead),
    :global(.statGroupHead),
    :global(.statGroupRest) {
        padding: 0.125rem 0.5rem;
        &:not(th) {
            font-weight: common.$a-font-weight-bold;
        }
    }

    :global(.statRest),
    :global(.statGroupRest) {
        text-align: right;
        padding: 0.125rem 0.125rem 0.125rem 1rem;
    }

    :global(.statFoot) {
        margin-top: 0.5rem;
    }
}
