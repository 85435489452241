.warning-wrapper {
    position: fixed;
    display: flex;
    flex-direction: column;
    align-items: center;
    top: calc(var(--decorator-sticky-offset) + 1rem);
    left: 0;
    width: 100%;
    z-index: 2147483601; // +1 from the content studio overlay
}
