@use 'common' as common;

.layout {
    background-color: white;
    @include common.full-width-mixin();
    padding-top: 1.25rem;
    padding-bottom: 0.75rem;
}

.header {
    padding-bottom: 0.75rem;

    @media #{common.$mq-screen-mobile-small} {
        padding-bottom: 0.5rem;
    }
}

.services {
    margin-bottom: 1.5rem;

    @media #{common.$mq-screen-mobile-small} {
        margin-bottom: 1rem;
    }
}

.cards {
    @include common.grid-layout-mixin();
    @include common.content-studio-drop-zone-fix-local();

    & > :empty {
        display: none;
    }
}
