@use 'common' as common;

.lenkepanelNavno {
    &:global(.navds-link-panel) {
        border: 1px solid var(--default-action-color);
    }

    :global {
        .navds-link-panel__content {
            display: flex;
        }

        .navds-link-panel__title,
        .navds-link-panel__chevron {
            color: var(--default-action-color);
        }
    }

    &:hover {
        :global(.navds-link-panel__title) {
            color: var(--default-action-color);
            text-decoration-line: underline;
            text-decoration-thickness: 0.05em;
            text-underline-offset: 0.1em;
        }
    }

    @media #{common.$mq-screen-mobile} {
        padding: 0.75rem 1rem;
    }
}

.innhold {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.ikon {
    display: flex;
    align-self: center;
    align-items: center;
    justify-content: center;

    padding: 0 1rem 0 0.5rem;

    width: 3rem;
    height: 3rem;

    & > img {
        max-width: 2rem;
        max-height: 2rem;
    }
}

.separator {
    width: 6rem;
    border: none;
    background-color: common.$a-gray-600;
    height: 1px;
    margin: 1rem auto;
}

.vertikal {
    flex-direction: column;
    text-align: center;

    :global {
        .navds-link-panel__chevron {
            display: none;
        }
    }

    .ikon {
        width: 100%;
        padding: 0 0 1rem 0;
        & > img {
            max-width: 100%;
        }
    }
}
