@use 'common' as common;

.redirectPage {
    width: 100%;
    background-color: white;
    padding: common.$editorPaddingVertical common.$editorPaddingHorizontal;
    border: 4px solid common.$a-green-200;
}

.loader {
    display: flex;
    justify-content: center;
}
