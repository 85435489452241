@use 'common' as common;

.page {
    display: grid;
    gap: var(--a-spacing-10);

    margin: 1.25rem auto var(--a-spacing-10);
    grid-template-areas:
        'main'
        'side';

    @media #{common.$mq-screen-tablet-and-desktop} {
        grid-template-columns: 6rem 2fr 6rem;
        grid-template-areas:
            'pictogram main .'
            '. side .';
    }

    &.withSideCol {
        @media #{common.$mq-screen-desktop-small-up} {
            grid-template-areas: 'pictogram main side';
            grid-template-columns: 6rem 2fr 1fr;
        }
    }
}

.main {
    grid-area: main;
}

.aside {
    grid-area: side;

    & > * {
        border-radius: common.$border-radius-large;
    }
}

.pictogram {
    display: none;
    grid-area: pictogram;

    @media #{common.$mq-screen-tablet-and-desktop} {
        width: 6rem;
        height: 6rem;
        display: block;
    }
}

/* Innrykk i ekspanderende paneler */

.main :global(.navds-expansioncard__content) {
    @media #{common.$mq-screen-desktop} {
        padding-left: 82px;
    }
}
