.gvItemEditor {
    display: flex;
    justify-content: space-between;

    .form {
        & > :not(:last-child) {
            margin-bottom: 0.5rem;
        }
    }

    .formButtons {
        & > *:not(:last-child) {
            margin-right: 0.5rem;
        }
    }

    .deleteConfirmMsg {
        margin-bottom: 0.5rem;
    }
}
