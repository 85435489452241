@use 'common' as common;

.newsItem {
    margin-bottom: 1.5rem;
    list-style-type: none;

    a {
        text-decoration-thickness: 0.05em;
        text-underline-offset: 0.1em;
        text-decoration-line: underline;
    }
}

.ingress {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
}

.newsTagline {
    display: flex;
    flex-direction: row;
    margin-bottom: 1.5rem;
}

.newsType {
    margin-left: 0.25rem;
    font-weight: 600;
}

.publishDate {
    margin-left: 0.75rem;
    color: var(--a-gray-800);
}
