@use 'common' as common;

.filtersMenu {
    box-shadow: common.$a-blue-200 inset 0 0 0 8px;
    border-radius: 0.2em;
    background-color: white;

    @include common.section-padding-mixin();

    :global(.ekspanderbartPanel) {
        border-bottom: 0;

        :global(.ekspanderbartPanel__innhold) {
            padding-left: 0;
        }
    }

    .category:global(.navds-fieldset) {
        margin-top: 1.5em;

        :global(.navds-fieldset__legend) {
            font-size: common.$a-font-size-large;
        }
        :global(.navds-checkboxes) {
            display: flex;
            align-items: flex-start;
            flex-direction: column;
        }
    }

    .introduction {
        margin: 1.5em 0;
    }
}
