@use 'common' as common;

.list {
    @include common.unstyled-list();
    margin-top: 2rem;
}

.item {
    padding: 1.5rem 0;
    border-top: 1px solid common.$a-gray-200;

    &:last-child {
        border-bottom: 1px solid common.$a-gray-200;
    }
}
