@use 'common' as common;

.newsPressHeader {
    padding-top: 1.25rem;
    padding-bottom: 0.75rem;
    margin: 0 auto -0.25rem auto;
    max-width: 63.5rem;

    @media #{common.$mq-screen-desktop} {
        padding-right: 2.8rem;
        padding-left: 2.8rem;
    }

    @media #{common.$mq-screen-tablet} {
        padding-right: 1.5rem;
        padding-left: 1.5rem;
    }

    @media #{common.$mq-screen-mobile} {
        padding-bottom: 0.5rem;
        padding-right: 0.5rem;
        padding-left: 0.5rem;
    }
}

.newsPressLine {
    border-bottom: 3px solid var(--a-gray-400);
    display: block;
    left: 0;
    margin-top: 1rem;
    position: absolute;
    width: 100vw;
}

.tagWrapper {
    align-items: center;
    display: flex;
    flex-direction: row;
    margin-bottom: 0.25rem;
}

.tagLabel {
    color: var(--a-purple-500);
    font-weight: common.$a-font-weight-bold;
    padding-left: 0.5rem;
    text-transform: uppercase;
}

.tagIcon {
    height: 1rem;
    width: 1rem;
}
