@use 'common' as common;

@mixin highlight {
    > :global(.situation) {
        background-color: var(--a-orange-200);
    }
    > :global(.product) {
        background-color: var(--a-green-200);
    }
    > :global(.tool) {
        background-color: var(--a-deepblue-200);
    }
    > :global(.themed-article) {
        background-color: var(--a-limegreen-300);
    }
    > :global(.guide) {
        background-color: var(--a-lightblue-200);
    }
    //TODO: Find a better way? (can't specify classes in Card Size modules here or vice versa)
    * {
        text-decoration-line: none;
        color: var(--a-gray-900) !important;
    }
}

.card {
    display: block;
    margin-bottom: var(--a-spacing-7);
    outline: 0;
    text-decoration-line: none;
    width: 100%;
    cursor: pointer;

    &.inline {
        display: inline-block;
        width: auto;
        padding: 0.4025rem 0;
        margin: 0;
        text-decoration-line: none;
    }

    .bed {
        transition: all 0.3s;
        height: 100%;
    }

    &:hover,
    &:focus-within {
        @media (hover: hover) {
            @include highlight;
        }
    }

    &:focus-within,
    &:active {
        @media (hover: none) {
            @include highlight;
        }

        .bed {
            position: relative;
            box-shadow: 0 0 0 3px var(--a-blue-800) !important;

            &:after {
                background-color: transparent;
                content: '';
                display: block;
                left: 0;
                pointer-events: none;
                position: absolute;
                top: 0;
            }
        }
        .bed:global(.large) {
            &:after {
                box-shadow: 0 0 0 4px white;
                border-radius: 6px;
                height: calc(100% - 8px);
                width: calc(100% - 8px);
                margin: 4px;
            }
        }
    }

    :global(.large) {
        background-color: white;
        border-radius: 8px;
        display: block;
        padding: 1.5rem;
        text-decoration: none;
        width: 100%;

        :global(.product),
        :global(.provider),
        :global(.guide),
        :global(.situation) {
            display: flex;
            flex-direction: column;
            height: 100%;
        }
        &:global(.product),
        &:global(.guide),
        &:global(.tool),
        &:global(.themed-article),
        &:global(.situation) {
            min-height: common.px-to-rem(400);
        }
        &:not(:hover):not(:active) {
            box-shadow: var(--a-shadow-medium);
        }
        &:hover {
            box-shadow:
                0 1px 3px rgba(38, 38, 38, 0.2),
                0 1px 6px rgba(0, 0, 0, 0.14),
                0 2px 8px rgba(38, 38, 38, 0.12);
        }

        &:global(.situation) {
            @media #{common.$mq-screen-mobile} {
                padding: 1rem;
            }
        }
    }
}

.lenkeBaseOverride {
    &:focus {
        outline: 0;
    }
}

// Handle card-margin in layouts
:global(.layout__situation-flex-cols),
:global(.layout__product-flex-cols) {
    .card {
        margin-bottom: 0;
    }
}
