.bulletList {
    margin: 0;
    padding-left: 1.4rem;

    li {
        margin-bottom: 0.5rem;
    }
}
.anchor {
    border-bottom: 1px var(--default-action-color) solid;
    text-decoration: none;
    transition: border-bottom-color 0.12s ease-out;
}
