@use 'common' as common;

.pressTopSection {
    display: flex;
    background-color: white;
    justify-content: center;
    margin-top: 2rem;
    @include common.full-width-mixin;
}

.content {
    width: calc(100% - 1rem);
    max-width: 37.5rem;
}

.pressCall {
    margin-top: 1.5rem;
    margin-bottom: 2.75rem;
    padding: 2.5rem;
    background-color: var(--a-purple-50);

    @media #{common.$mq-screen-mobile} {
        padding: 1rem;
    }

    p:last-of-type {
        margin-bottom: 0;
    }

    a {
        color: var(--a-gray-900);
        text-decoration-line: underline;
        text-decoration-thickness: 0.05em;
        text-underline-offset: 0.1em;
        border: 0;
    }
    a:hover {
        text-decoration: none;
        color: var(--default-action-color);
    }
}
