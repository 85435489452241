@use 'common' as common;

$margin: 2rem;

.currentTopicPage {
    display: flex;
    flex-direction: column;
    padding-top: 2rem;
    padding-bottom: 3rem;

    @media #{common.$mq-screen-mobile} {
        padding-left: 1rem;
        padding-right: 1rem;
    }
}

.contentAligner {
    display: flex;
    width: 100%;
    max-width: 63.5rem;
}

.contentWrapper {
    display: flex;
    justify-content: center;
    padding-bottom: var(--a-spacing-16);
    @include common.full-width-mixin;
}

.content {
    display: flex;
    width: 100%;
    max-width: 37.5rem;

    & > * {
        width: 100%;
    }
}
