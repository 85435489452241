@use 'common' as common;

.header {
    display: flex;
    align-items: center;
}

.button {
    margin-left: 1rem;
}
