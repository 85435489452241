.pageHeading {
    width: 100%;
    text-align: center;
    padding-top: 1.25rem;
    margin-bottom: 2.25rem;

    .ingress {
        margin-top: 0.6rem;
    }
}
