@use 'common' as common;

.tagLine {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 0.25rem;

    @include common.full-width-mixin;
}

.wrapper {
    display: flex;
    max-width: 63.5rem;
    width: 100%;
}

.tagLabel {
    color: var(--a-lightblue-800);
    font-weight: common.$a-font-weight-bold;
    padding-left: 0.5rem;
    text-transform: uppercase;
}

.tagIcon {
    height: 1.25rem;
    width: 1.25rem;
}
