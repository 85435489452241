@use 'common' as common;

.menuList {
    display: flex;
    flex-direction: column;
    width: 100%;

    :global(.navds-accordion) {
        --__ac-accordion-header-shadow-color: none;
    }

    .panel {
        margin-bottom: 0.5rem;
        background-color: common.$a-white;
        border-radius: common.$border-radius-base;

        :global(.navds-accordion__header) {
            border-radius: common.$border-radius-base;

            &:not(:focus-visible) {
                box-shadow: 0 0 0;
            }

            &:hover {
                background-color: common.$a-white;
            }
        }

        &:global(.navds-accordion__item--open) {
            :global(.navds-accordion__header) {
                border-bottom-left-radius: 0;
                border-bottom-right-radius: 0;
            }
        }

        ul {
            list-style: none;
            width: 100%;
            margin: 0;
            padding: 0.375rem 0;
            font-size: 1.125rem;

            li:not(:last-child) {
                margin-bottom: 1rem;
            }
        }
    }
}
