@use 'common' as common;

$navGronnLighten40Filter: invert(98%) sepia(83%) saturate(920%) hue-rotate(62deg) brightness(85%)
    contrast(80%);
$redErrorFilter: invert(22%) sepia(84%) saturate(1767%) hue-rotate(346deg) brightness(100%)
    contrast(88%);

.editorHelp {
    display: flex;
    margin: 1rem 0;

    .icon {
        // Ensure the icon size is not overridden by whichever component the helper
        // is inserted into
        height: 1.5rem !important;
        width: 1.5rem !important;
        margin-right: 1rem !important;

        &.info,
        &.arrowDown,
        &.arrowUp,
        &.help {
            filter: $navGronnLighten40Filter;
        }
        &.error {
            filter: $redErrorFilter;
        }
    }

    .content {
        align-self: flex-start;

        &.error {
            color: common.$a-red-600;
        }
    }
}
