@use 'common' as common;

.header {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;

    &:focus {
        outline: none;
        box-shadow: none;
    }
}

.anchorOffset {
    position: relative;
    transform: translateY(calc(var(--a-spacing-20) * -1));
}

.anchorLink {
    font-size: inherit;
    color: inherit;
    text-decoration: inherit;
}
