@use 'common' as common;

.themedPageHeader {
    display: flex;
    justify-content: center;
    background-color: white;
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
    border-bottom: 4px solid transparent;

    @include common.full-width-mixin;

    &.situation {
        border-bottom-color: var(--a-orange-500);
    }
    &.product {
        border-bottom-color: var(--a-green-500);
    }
    &.guide {
        border-bottom-color: var(--a-lightblue-500);
    }
    &.themedpage {
        border-bottom-color: var(--a-limegreen-500);
    }
    &.tool {
        border-bottom-color: var(--a-deepblue-500);
    }
    &.intermediateStep {
        border-bottom-color: var(--a-deepblue-300);
    }
    &.overview {
        border-bottom-color: var(--a-red-100);
    }
    &.generic {
        border-bottom-color: var(--a-gray-600);
    }

    @media #{common.$mq-screen-mobile} {
        padding-left: 1rem;
        padding-right: 1rem;

        .illustration {
            display: none;
        }
    }
}

.header {
    padding: 0 0 1.25rem 0;
    margin: 0 auto;
}

.illustration {
    width: 5rem;
    height: 5rem;
    margin-right: 2rem;
    pointer-events: none;
}

.text {
    width: 100%;
    max-width: 63.5rem;
}

.taglineWrapper {
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    @media #{common.$mq-screen-mobile} {
        flex-direction: column;
        align-items: stretch;
    }
}

.taglineLabel {
    @media #{common.$mq-screen-mobile} {
        margin-bottom: 0.5rem;
    }
}

.divider {
    padding: 0 1rem;

    @media #{common.$mq-screen-mobile} {
        display: none;
    }
}

.divider,
.modifiedLabel {
    color: common.$a-gray-700;
}
