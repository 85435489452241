@use 'common' as common;

.pageNavigationMenu {
    margin: var(--a-spacing-6) 0 var(--a-spacing-14);
    padding: 1rem 1.25rem;
    border-radius: 0.5rem;
    background-color: var(--brand-beige);

    &.chapterNavigation {
        border-top: 5px solid var(--brand-purple-deep);
        border-top-right-radius: 0;
        border-top-left-radius: 0;
        margin: var(--a-spacing-6) 0 0 0;
    }

    .heading {
        margin-bottom: var(--a-spacing-4);
    }

    .list {
        @include common.unstyled-list();
        display: flex;
        flex-direction: column;
        gap: 0.75rem;
    }

    .icon {
        width: 16px;
        height: 16px;
    }

    .link {
        display: inline-flex;
        align-items: center;
        gap: 0.375rem;
        text-underline-offset: 2.5px;
        text-decoration-line: none;
        text-decoration-thickness: 2px;
        color: var(--default-action-color);

        &:hover {
            text-decoration-line: underline;
        }
    }
}
