.contactOption {
    background-color: transparent;
    display: flex;
    width: 100%;
    gap: var(--a-spacing-4);

    .content {
        display: flex;
        align-self: flex-start;
        flex-direction: column;
        justify-content: center;
    }

    .link {
        color: var(--default-action-color);
        text-decoration-thickness: 1px;
        text-underline-offset: 0.1em;
        text-decoration-line: underline;
        margin-bottom: 0;

        &:hover,
        &:focus {
            text-decoration-line: none;
        }

        :global(.navds-heading) {
            color: var(--default-action-color);
        }
    }

    .text,
    :global(.navds-body-long.navds-body-long--medium) {
        color: black;
        margin-bottom: 0;

        :global(.navds-body-long) {
            margin-bottom: var(--a-spacing-4);
        }
    }
}
