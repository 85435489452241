@use 'common' as common;

$chatPointerSize: 1.5rem;

.chat {
    $bgColor: var(--a-white);
    $borderRadius: 24px;

    position: relative;
    background-color: $bgColor;
    border-radius: $borderRadius $borderRadius $borderRadius 0;

    &:after {
        content: '';
        position: absolute;
        bottom: -$chatPointerSize;
        left: 0;
        width: 0;
        height: 0;
        border-right: $chatPointerSize solid transparent;
        border-top: $chatPointerSize solid $bgColor;
        display: block;
    }
}
