@use 'common' as common;

.globalValuesPage {
    width: 100%;
    background-color: white;
    padding: common.$editorPaddingVertical common.$editorPaddingHorizontal;
    border: 4px solid common.$a-green-200;

    .headerRow {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .sortSelector {
        width: 100%;
        max-width: 10rem;
    }

    .content {
        display: flex;
        width: 100%;
        margin-top: 1rem;

        @media #{common.$mq-screen-mobile} {
            flex-direction: column;
        }
    }

    .leftCol {
        min-width: 60%;
        width: 100%;
    }

    .subHeaderRow {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        width: 100%;
        margin-bottom: 0.75rem;
    }
}
