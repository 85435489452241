@use 'common' as common;

.currentTopics {
    background-color: var(--bg-color, common.$a-bg-subtle);
    padding: 2.75rem;
    @include common.full-width-mixin();

    @media #{common.$mq-screen-mobile} {
        padding-top: 2rem;
        padding-bottom: 2rem;
    }
}

.header {
    margin-bottom: 1.5rem;

    @media #{common.$mq-screen-mobile} {
        margin-bottom: 1rem;
    }
}

.list {
    list-style: none;
    position: relative;
    padding: 0;

    @include common.grid-layout-mixin(
        $numCols: 2,
        $rowGapMobileSmall: 0.75rem,
        $columnGapMobileSmall: 0.75rem
    );
}

.item {
    background-color: var(--item-color, var(--a-white));
    height: 100%;

    :global(.linkPanelNavnoTextContent) {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
    }

    @media #{common.$mq-screen-mobile-small} {
        padding: 0.5rem 0.75rem;
    }
}

.date {
    color: common.$a-gray-700;
}
