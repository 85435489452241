@use 'common' as common;

.notSupported {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 2rem;
    padding: 2rem;
    background-color: white;
    border: 4px solid common.$a-green-200;
}

.type {
    font-weight: normal;
}
