@use 'common' as common;

.gvItems {
    display: flex;
    flex-direction: column;
}

.itemOuter {
    padding: 0.5rem;
}

.item {
    display: flex;
    padding: 0.5rem;
    margin: 0 -0.5rem;
    background-color: white;
    border-radius: common.$border-radius-base;
    box-shadow: 1px 2px 3px 2px common.$a-gray-400;
}
