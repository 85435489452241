@use 'common' as common;
@use '../AreaCard.module' as areaCard;

.graphics {
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: hidden;
    pointer-events: none;

    & > * {
        @include areaCard.transitionMixin;
    }
}

.insideCard {
    @media only screen and (max-width: common.$desktopMaxWidth) {
        transform: scale(areaCard.$size-medium) translateX(-(areaCard.$size-medium) * 10%);
        width: (1 / areaCard.$size-medium) * 100%;
        height: (1 / areaCard.$size-medium) * 100%;
    }

    @media #{common.$mq-screen-mobile-small} {
        transform: scale(areaCard.$size-small) translateX(-(areaCard.$size-small) * 100%);
        width: (1 / areaCard.$size-small) * 100%;
        height: (1 / areaCard.$size-small) * 100%;
    }
}
