.headerPart {
    margin-top: var(--a-spacing-11);
    margin-bottom: var(--a-spacing-2);
}

:global(.part__dynamic-header):last-child {
    .headerPart {
        margin-bottom: 0;
    }
}

:global(.part__dynamic-header):first-child {
    .headerPart {
        margin-top: 0;
    }
}
