@use 'common' as common;

.summaryError {
    margin-top: 2rem;
    background-color: common.$a-orange-200;
}

.summaryText {
    margin-top: 2rem;
    background-color: common.$a-lightblue-100;
}
