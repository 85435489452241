@use 'common' as common;

.filterExplanation {
    margin-left: -0.5rem;
    padding: 0.5rem 0.3rem 0.5rem 0.5rem;
    display: flex;
    align-items: center;
    transition: all 0.8s linear;
    border-radius: 3px;
    width: calc(100% + 1rem);
    font-size: 1rem;
    border: 1px solid transparent;
}

.filterExplanationHighlight {
    background-color: common.$a-green-100;
    border: 1px solid common.$a-green-500;
    transition: all 0.4s linear;
}

.iconWrapper {
    display: block;
    height: 1.5rem;
    width: 1.5rem;
    margin-right: 0.25rem;
    position: relative;

    .highlighted {
        color: common.$a-surface-success;
    }
}

.icon {
    display: block;
    height: 1.5rem;
    width: 1.5rem;
    left: 0;
    position: absolute;
    top: 0;
    transition: all 0.4s;
}

.iconVisible {
    opacity: 1;
}

.iconHidden {
    opacity: 0;
}
