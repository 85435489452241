@use 'common' as common;

.pressShortcuts {
    background-color: white;
    display: flex;
    justify-content: center;
    margin-bottom: -2rem;
    margin-top: 2.5rem;
    padding: 2.75rem 0;
    @include common.full-width-mixin;
}

.content {
    width: calc(100% - 1rem);
    max-width: 37.5rem;
}

.shortcutItem,
.shortcutItem:hover {
    border: 0;
    border-bottom: 2px solid var(--a-gray-600);
    margin: 0.25rem 0;
    font-weight: 600;

    &:hover {
        box-shadow: none;
    }

    :global(.navds-link-panel__content) {
        font-size: 1.25rem;
    }
}

.shortcutList {
    list-style: none;
    margin: 1.5rem 0;
    padding: 0;
}
