@use 'common' as common;

.dateLine {
    background-color: white;
    display: flex;
    justify-content: center;
    margin-top: 1.5rem;

    @include common.full-width-mixin;
}

.container {
    max-width: 63.5rem;
    width: 100%;
}

.dateLabel {
    color: var(--a-text-subtle);
    margin-bottom: 0.75rem;
}
