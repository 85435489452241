@use 'common' as common;

.gvMessages {
    display: flex;
    flex-direction: column;
    width: 40%;
    border-left: 2px dotted common.$a-gray-600;
    padding-left: 1rem;
    margin-left: 1rem;

    @media #{common.$mq-screen-mobile} {
        width: 100%;
        margin-left: 0;
        margin-top: 1rem;
        padding-left: 0;
        padding-top: 1rem;

        border-left: none;
        border-top: 2px dotted common.$a-gray-600;
    }

    .warning {
        font-weight: bold;
    }
    .error {
        font-weight: bold;
        color: common.$a-red-500;
    }
    .close {
        align-self: center;
        margin-top: 1rem;
    }
}
