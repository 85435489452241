@use 'common' as common;

.personShortcuts {
    @include common.full-width-mixin();
    padding-top: 2.25rem;
    padding-bottom: 2.25rem;
    background-color: var(--a-red-50);

    @media #{common.$mq-screen-mobile} {
        padding: 2rem 1rem 0.5rem;
    }

    .list {
        display: grid;
        grid-template-columns: auto auto;
        list-style: none;
        padding: 0;
        column-gap: 2rem;

        @media #{common.$mq-screen-mobile} {
            grid-template-columns: auto;
        }

        .listItem {
            width: 100%;
            display: flex;
            padding: 0.75rem 0;
            border-top: 1px solid var(--Global-Gray-300, #cbcfd5);

            &:nth-child(1),
            &:nth-child(2) {
                border-top: none;
            }

            @media #{common.$mq-screen-mobile} {
                &:nth-child(2) {
                    border-top: 1px solid var(--Global-Gray-300, #cbcfd5);
                }
            }

            .linkPanel {
                display: flex;
                text-decoration-thickness: 0.05em;
                text-decoration-color: var(--a-text-default);
                text-underline-offset: 0.1em;
                align-items: center;
                padding: 0;
                gap: 0.5rem;
                width: 100%;

                &:hover {
                    text-decoration-line: none;
                }

                &:focus {
                    @include common.panel-focus-mixin(true);
                }

                .text {
                    color: var(--a-text-default);
                    font-feature-settings:
                        'liga' off,
                        'clig' off;
                    font-size: 24px;
                    font-style: normal;
                    font-weight: 400;

                    @media #{common.$mq-screen-mobile-small} {
                        font-size: 20px;
                        letter-spacing: -0.02px;
                    }
                }
                .icon {
                    display: flex;
                    flex-shrink: 0;
                    width: 2rem;
                    height: auto;
                    color: var(--a-icon-subtle);

                    margin: 4px;

                    & > * {
                        width: 100%;
                        height: 100%;
                    }
                }
            }
        }
    }
}
