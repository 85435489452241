@use 'common' as common;

.tableOfContents {
    margin-top: 2rem;

    ol {
        @include common.unstyled-list();
        border-top: 1px solid #d1d1d1;
    }
    li {
        font-size: 1.2rem;
        border-bottom: 1px solid #d1d1d1;
    }
    a {
        color: #3e3832;
        font-weight: 600;
        display: block;
        padding: 0.43rem 0;
        position: relative;
        text-decoration: none;

        &:hover,
        &:active {
            color: #0056b4;
        }
        &:focus {
            outline: none;
            color: white;
            background-color: common.$a-border-focus;
            box-shadow: 0 0 0 2px common.$a-border-focus;
        }
        &:hover:before,
        &:focus:before,
        &:active:before {
            border: 0.1875rem solid #0056b4;
            background-color: #0056b4;
            border-radius: 50%;
            display: inline-block;
            content: ' ';
            position: absolute;
            left: -1rem;
            top: 1rem;
        }
    }
}
