@use 'common' as common;

.frontpageCard {
    margin-bottom: 0;
    height: 100%;

    :global(.employer-frontpage),
    :global(.person-frontpage),
    :global(.provider-frontpage) {
        padding: 1.5rem;

        a {
            font-size: var(--a-font-size-heading-medium);
            line-height: var(--a-font-line-height-heading-medium);
        }

        border: 0;

        @media #{common.$mq-screen-mobile} {
            padding: 1rem 0.5rem;

            a {
                font-size: var(--a-font-size-heading-small);
                line-height: var(--a-font-line-height-heading-small);
            }
        }
    }

    :global(.employer-frontpage),
    :global(.person-frontpage) {
        &:not(:hover):not(:active) {
            box-shadow: 0 0 0;
        }
        &:hover {
            box-shadow: 0 0 0;
        }
    }

    :global(.employer-frontpage) {
        background-color: var(--a-orange-200);
    }
    :global(.person-frontpage) {
        background-color: var(--a-deepblue-50);
    }

    :global([class*='MiniCard'].provider-frontpage) {
        background-color: var(--a-red-50);
        box-shadow: var(--a-shadow-xsmall);

        :global(.back) {
            svg {
                path,
                rect,
                circle {
                    fill: var(--a-red-100);
                }
            }
        }

        &:hover {
            background-color: var(--a-red-100);
            :global(.back) {
                svg {
                    path,
                    rect,
                    circle {
                        fill: var(--a-white);
                    }
                }
            }
        }
    }
    :global([class*='MiniCard'].person-frontpage) {
        background-color: var(--a-deepblue-50);

        :global(.back) {
            svg {
                path,
                rect,
                circle {
                    fill: var(--a-deepblue-100);
                }
            }
        }

        &:hover {
            background-color: var(--a-deepblue-100);
            :global(.back) {
                svg {
                    path,
                    rect,
                    circle {
                        fill: var(--a-deepblue-50);
                    }
                }
            }
        }
    }
}
