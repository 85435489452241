@use 'common' as common;

.versionSelector {
    position: relative;
    top: 0.5rem;
    z-index: 1;
    display: flex;
    justify-content: center;
    width: 100%;
    perspective: 500px;
    perspective-origin: top;
    max-width: common.px-to-rem(480);

    .inner {
        position: absolute;
        visibility: hidden;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        justify-content: center;
        transform: rotateX(-90deg);
        transform-origin: top;
        transition-property: visibility, transform;
        transition-duration: 0.25s;
        transition-timing-function: ease-out;
        background-color: white;
        border-radius: common.$border-radius-base;
        box-shadow: 0 0 0 2px var(--a-green-200) inset;
        padding: 1rem 2rem;

        &.open {
            visibility: visible;
            transform: rotateX(0);
        }
    }
    .typeSelector {
        margin-bottom: 0.25rem;
    }
    .input {
        display: flex;
        width: 24rem;
        max-width: 100%;
    }
}

.spinnerContainer {
    display: flex;
    flex-direction: column;
}

.error {
    margin-top: 1rem;
}
