@use 'common' as common;

.button {
    white-space: break-spaces;
    color: var(--default-action-color);
    box-shadow: inset 0 0 0 2px var(--default-action-color);

    &__icon {
        height: 1.5rem;
    }
    :global(.navds-label) {
        text-align: center;
    }
}

.buttonFullWidth {
    width: 100%;
}
