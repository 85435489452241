.fallbackChevron {
    position: relative;
    margin-right: 1rem;

    .front,
    .back {
        position: absolute;

        svg {
            width: 100%;
            height: 100%;
        }
    }
}
