@use 'common' as common;

.container {
    display: flex;
    flex-direction: row;
    gap: 1.5rem;
    padding: 1rem 1.25rem;
    width: 100%;
    height: 100%;
    position: relative;
    cursor: pointer;

    border-width: 1px;
    border-radius: 0.5rem;
    border-style: solid;
    border-color: var(--a-gray-300);

    @media #{common.$mq-screen-mobile-small} {
        flex-direction: column;
        gap: 0.75rem;
    }

    &:hover {
        background-color: #ecf3fe;

        .link {
            text-decoration-thickness: 2px;
        }
    }

    &:focus-within {
        box-shadow: var(--a-shadow-focus);
    }
}

.illustration {
    width: 3.75rem;
    height: 3.75rem;
    flex-shrink: 0;

    :global(.back) {
        @include common.brand-purple-filter-back();
    }
    :global(.front) {
        @include common.brand-purple-filter-front();
    }
}

.link {
    text-underline-offset: 0.15625rem;
    text-decoration-thickness: 1px;

    &:focus-visible {
        outline: none;
    }
}

.linkText {
    margin-bottom: 0.5rem;
}

.description {
    margin-bottom: 1.5rem !important;
}

.tagline {
    letter-spacing: 0.025rem;
    font-variant-caps: all-small-caps;
}
