@use 'common' as common;

.header {
    font-weight: common.$a-font-weight-bold;
}

.wrapper {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    column-gap: 0.75rem;
    margin-top: var(--a-spacing-1);
    margin-bottom: var(--a-spacing-7);
}

.container {
    &:hover,
    &:focus {
        .micro {
            background-color: #ecf3fe;
        }
    }
}

.micro {
    border-radius: 16px;
    border: 1px solid #b0b0b0;
    box-shadow: 0 0 0 3px var(--default-action-color);
    color: var(--a-gray-900);
    display: inline-flex;
    font-size: 1rem;
    letter-spacing: 0.01em;
    line-height: 1.25rem;
    padding: 0.25rem 0.5rem;
    position: relative;

    &:not(:hover):not(:active) {
        box-shadow:
            0 1px 3px rgba(38, 38, 38, 0.2),
            0 1px 6px rgba(0, 0, 0, 0.14),
            0 2px 8px rgba(38, 38, 38, 0.12);
    }

    &:hover,
    &:focus {
        border: 1px solid transparent;
    }

    &:before {
        content: '';
        align-self: center;
        position: relative;
        display: flex;
        width: 0.5rem;
        height: 0.5rem;
        border-radius: 0.5rem;
        margin: 0 0.5rem 0 0;
        flex: none;
        background-color: var(--brand-purple-deep);
    }

    &:after {
        border-radius: 16px;
        box-shadow: 0 0 0 2px white;
        height: calc(100% - 2px);
        margin: 1px;
        width: calc(100% - 2px);
    }
}
