@use 'common' as common;

$margin: 2rem;

.officePage {
    display: flex;
    flex-direction: column;
}
.pageContent {
    display: flex;
    flex-direction: column;

    :global {
        .layout__section-with-header {
            max-width: common.$sectionMaxWidth;
            margin-bottom: $margin;
        }
    }
}

.content {
    margin-top: 2rem;
    display: flex;
    justify-content: flex-start;
    align-self: center;
    width: 100%;
    max-width: common.$contentMaxWidth;

    & > * {
        width: 100%;
    }
}
