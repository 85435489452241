@use 'common' as common;

$padding: 1.5rem;
$iconSize: 2.1875rem;

.linkPanel {
    &:global(.navds-panel) {
        width: 100%;
        display: flex;
        padding: $padding;
        margin: 0;
        background-repeat: no-repeat;
        background-position: bottom right;
        background-size: contain;
        background-color: white;
        border: 1px solid var(--default-action-color);
        box-sizing: border-box;
        text-decoration-line: none;
        color: black;
    }
    &:hover {
        box-shadow: 0 2px 1px 0 #a0a0a0;

        .title {
            text-decoration-thickness: 0.05em;
            text-underline-offset: 0.1em;
            text-decoration-line: underline;
        }
    }
    &:focus,
    &:focus-visible,
    &:active {
        @include common.panel-focus-mixin(false);
    }
    .innhold {
        height: 100%;
        width: 100%;
        display: flex;
        justify-items: flex-start;
        align-items: flex-start;
        flex-direction: column;
        text-align: start;
    }
    .header {
        width: 100%;
    }
    .title {
        width: 100%;
        color: var(--default-action-color);
    }
    .ingress {
        width: 100%;
        margin: 1rem 0 0.5rem 0;
    }
}

:global(.vertical).linkPanel {
    text-align: center;

    .icon {
        $marginBottom: 1rem;

        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
        margin-bottom: $marginBottom;

        img {
            height: $iconSize;
        }
        &.bg {
            margin: (-$padding) (-$padding) $marginBottom (-$padding);
            padding: 0 1rem;
            width: calc(100% + #{$padding} * 2);
            height: 6rem;

            img {
                height: auto;
                max-height: 5rem;
            }
        }
    }
    .title {
        text-align: center;
    }
    .ingress {
        text-align: center;
    }
}

:global(.horizontal).linkPanel {
    .icon {
        margin-right: 0.5rem;

        img {
            width: $iconSize;
            height: $iconSize;
        }
    }
    .header {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: row;
    }
    .innhold {
        padding-right: 0.5rem;
    }
}
