@use 'common' as common;

.pressNews {
    display: flex;
    justify-content: center;
    margin-top: 2.5rem;
    @include common.full-width-mixin;
}

.content {
    width: calc(100% - 1rem);
    max-width: 37.5rem;
}

.newsList {
    margin: 1rem 0 2rem 0;
    padding: 0;
}
