@use 'common' as common;

.versionStatus {
    display: flex;
    padding: 1rem 3rem;
    margin-bottom: 1rem;
    background-color: white;
    border-radius: common.$border-radius-base;
    box-shadow: 0 0 0 2px var(--a-orange-500) inset;
}
