@use 'common' as common;

.socialMedia {
    text-align: center;
    margin-top: 4rem;

    ul {
        @include common.unstyled-list();
    }
    li {
        margin: 0 2rem 0 0;
        display: inline-block;

        &:last-child {
            margin-right: 0;
        }
    }
    .ikon {
        align-items: center;
        display: flex;
        justify-content: center;
        width: 2rem;
        height: 2rem;
        border-radius: 50%;
        border: 1px solid var(--default-action-color);

        &:focus,
        &:hover {
            background-color: var(--default-action-color);
        }
    }
    img {
        height: 1rem;
    }
}
