@use '../../AreaCardGraphicsCommon.module' as areaGraphics;
@use '../../../AreaCard.module' as areaCard;

.shapes {
    @include areaCard.transitionMixin;
    position: absolute;
    right: -12px;
    top: 124px;
}

@include areaGraphics.onExpandMixin('.shapes') {
    right: 190px;
}

.letterPartOrange {
    background-color: var(--a-orange-200);

    @include areaCard.transitionMixin;
    position: absolute;
    width: 56px;
    height: 48px;
    right: -21px;
    top: 53px;
}

@include areaGraphics.onExpandMixin('.letterPartOrange') {
    right: 51px;
    top: 73px;
}

.letterPartBlue {
    background-color: var(--a-deepblue-200);

    @include areaCard.transitionMixin;
    position: absolute;
    width: 49.23px;
    height: 160px;
    right: -49px;
    top: 0;
    transform: skewX(18.8967deg);
}

@include areaGraphics.onExpandMixin('.letterPartBlue') {
    right: 27px;
}

.mask {
    background-color: var(--a-deepblue-100);

    @include areaCard.transitionMixin;
    position: absolute;
    width: 193.56px;
    height: 499px;
    right: -42px;
    top: -100px;
    transform: skewX(-22.09deg);
}

@include areaGraphics.onExpandMixin('.mask') {
    background-color: #ffb8b8;
    width: 49.23px;
    height: 160px;
    right: 82px;
    top: 0;

    transform: skewX(-18.8967deg);
}

.briefcase {
    @include areaCard.transitionMixin;
    position: absolute;
    right: 50px;
    top: 39px;
}

@include areaGraphics.onExpandMixin('.briefcase') {
    right: 102px;
    top: 83px;
}
