@use 'common' as common;

.container {
    display: flex;
    flex-direction: column;
    padding: 1rem 2rem;
    background-color: white;
    margin-bottom: 1rem;
    border: 2px solid common.$a-green-200;
    border-radius: common.$border-radius-large;
}

.loader {
    display: inline-flex;
    align-self: center;
    align-items: center;
    & > :first-child {
        margin-right: 1rem;
    }
}
