@use 'common' as common;

.officeInformation {
    background-color: common.$a-surface-default;
    padding: 1.68rem;

    header {
        margin-bottom: 1rem;

        h1 {
            font-size: 2.1rem;
            font-weight: 400;
            margin: 0.5rem 0 0;
        }

        h2 {
            font-size: 1.2rem;
            font-weight: 600;
            margin-top: 1.5rem;
        }
    }

    h3,
    h5 {
        margin-bottom: 0;
    }

    p:last-child,
    p:last-of-type {
        margin-bottom: 1.5rem;
    }
}
