.wrapper {
    width: 100%;
    margin-top: var(--a-spacing-10);

    & > * {
        max-width: 600px;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
    }
}
