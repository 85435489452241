@use 'common' as common;

.container {
    @include common.full-width-mixin();
    top: var(--decorator-sticky-offset);
    z-index: 2;
    padding-top: 1rem;
    padding-bottom: 1rem;
}

.warning > :global(.navds-alert__wrapper) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 100%;
    width: 100%;
}

.whiteBg {
    background-color: white;
}
