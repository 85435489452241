.image {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    &:empty {
        display: none;
    }
}
