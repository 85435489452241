// Set this class on an element to always show the expanded version of any contained graphics
$expandGraphics: expandGraphics;

// Set this class on an element to transition on hover to the expanded version of any contained graphics
$expandOnHover: expandOnHover;

@mixin onExpandMixin($graphics-element) {
    :global(.#{$expandOnHover}):hover #{$graphics-element} {
        @media (hover) {
            @content;
        }
    }

    :global(.#{$expandGraphics}) #{$graphics-element} {
        @content;
    }
}

:export {
    expandGraphics: $expandGraphics;
    expandOnHover: $expandOnHover;
}
