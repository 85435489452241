@use 'common' as common;
@use 'sass:math';

$iconContainerRadius: common.px-to-rem(64px / 2);

.container {
    $iconContainerSize: $iconContainerRadius * 2;
    $defaultBgColor: white;

    @mixin adjust-for-padding($sectionPadding) {
        $marginActual: 1rem;
        top: calc(-#{$sectionPadding} - #{$iconContainerRadius});
        margin-bottom: (-$iconContainerRadius) - ($sectionPadding - $marginActual);
    }

    display: inline-flex;
    flex-direction: column;
    background-color: $defaultBgColor;
    width: 100%;

    @include common.section-padding-mixin();

    :global(.icon-container) {
        display: flex;
        justify-content: center;
        align-items: center;
        align-self: center;
        width: $iconContainerSize;
        height: $iconContainerSize;
        position: relative;
        border-radius: 50%;
        background-color: $defaultBgColor;
        overflow: hidden;

        @include adjust-for-padding(common.$sectionPaddingDesktop);

        @media #{common.$mq-screen-tablet} {
            @include adjust-for-padding(common.$sectionPaddingTablet);
        }

        @media #{common.$mq-screen-mobile} {
            @include adjust-for-padding(common.$sectionPaddingMobile);
        }

        img {
            $iconSize: $iconContainerRadius / math.sqrt(common.stripUnit($iconContainerRadius)) * 2;
            height: common.round-rem($iconSize);
            width: common.round-rem($iconSize);
        }
    }
}

.withIcon {
    margin-top: $iconContainerRadius;
}

.header {
    margin-bottom: 1rem;
}

.headerWithIcon {
    margin-top: 1rem;
}
