@use 'common' as common;

.left {
    display: flex;
    flex-direction: column;
    margin-right: 1rem;
    width: 50%;

    .time {
        margin: 0.5rem 0;
    }
    .date,
    .time {
        min-height: common.$versionSelectorMinHeight;
        padding: 0.5rem;
    }
}

.right {
    align-self: flex-end;
    width: 50%;

    :global(.version-selector-submit) {
        margin-top: 0.5rem;
        width: 100%;
        min-height: common.$versionSelectorMinHeight;
    }
}
