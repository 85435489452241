@use 'common' as common;
.appContainer {
    max-width: common.$desktopMaxWidth;
    min-height: 22.5rem;
    margin: 0 auto;
    font-size: common.$a-font-size-large;
    line-height: common.$a-font-line-height-xlarge;

    @media #{common.$mq-screen-mobile} {
        padding: 0 common.$padding-sides-mobile 0 common.$padding-sides-mobile;
    }

    @media #{common.$mq-screen-tablet} {
        padding: 0 common.$padding-sides-tablet 0 common.$padding-sides-tablet;
    }

    @media #{common.$mq-screen-desktop} {
        padding: 0 common.$padding-sides-desktop 0 common.$padding-sides-desktop;
    }
}

.defaultBackground {
    background-color: common.$a-bg-subtle;
}

.whiteBackground {
    background-color: var(--a-white);
}

.contentWrapper {
    display: inline;
    &:focus {
        outline: none;
        box-shadow: none;
    }

    // Variations, depending on the spacing need set for this
    // particular page.
    &.whiteBackground:before {
        background-color: var(--a-bg-default);
    }
}

// Prevent accidental page customizations on legacy types which we genereally
// never want to customize
.legacyType {
    //  Prevent drag/drop interaction with components
    :global(.xp-page-editor-region-view) {
        pointer-events: none !important;
    }

    // Hide region dropzones and component-selectors
    :global(.xp-page-editor-item-placeholder) {
        display: none !important;
    }
}
