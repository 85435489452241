@use 'common' as common;

.textWithIndicator {
    display: flex;
    flex-direction: column;
    margin-top: 1rem;
}

.prefix {
    font-weight: bold;
    display: inline-block;
}

.text {
    display: inline-flex;
    align-items: center;
    gap: var(--a-spacing-2);
}

.indicator {
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    display: flex;
    height: 1rem;
    width: 1rem;
}

.indicatorInner {
    border-radius: 50%;
    display: flex;
    height: 0.6rem;
    width: 0.6rem;
}

.active {
    background-color: var(--a-surface-success-subtle);

    .indicatorInner {
        background-color: var(--a-surface-success);
        opacity: 0.7;
    }
}

.inactive {
    background-color: var(--a-surface-neutral-subtle);

    .indicatorInner {
        background-color: var(--a-surface-neutral);
        opacity: 0.7;
    }
}
