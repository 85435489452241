@use 'common' as common;

.panel {
    display: flex;
    padding-bottom: 1.75rem;
    background-color: common.$a-white;
    padding-top: 2rem;
    @include common.full-width-mixin();

    @media #{common.$mq-screen-mobile} {
        padding-bottom: 0.75rem;
        padding-left: 1rem;
        padding-right: 1rem;
    }

    @media #{common.$mq-screen-tablet} {
        padding-left: 2rem;
        padding-right: 2rem;
    }
}

.headerContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1;
    width: 100%;

    @media #{common.$mq-screen-mobile} {
        min-width: 100%;
        max-width: 100%;
    }
}

.gfxContainer {
    display: flex;
    justify-content: center;
    align-self: flex-end;
    flex: 1;
    height: common.px-to-rem(160px);
    margin-bottom: -1.75rem;
    pointer-events: none;

    > * {
        width: 350px;
    }

    @media #{common.$mq-screen-mobile} {
        display: none;
    }
}
