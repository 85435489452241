@use '../../AreaCardGraphicsCommon.module' as areaGraphics;
@use '../../../AreaCard.module' as areaCard;

.compass {
    @include areaCard.transitionMixin;
    position: absolute;
    right: 0;
    top: 120px;
    transform: rotate(-45deg);
}

@include areaGraphics.onExpandMixin('.compass') {
    right: 201px;
    transform: rotate(0deg);
}

.letterS {
    @include areaCard.transitionMixin;
    position: absolute;
    right: -74px;
    top: 0;
}

@include areaGraphics.onExpandMixin('.letterS') {
    right: 1px;
}

.mask {
    background-color: var(--a-deepblue-100);

    @include areaCard.transitionMixin;
    position: absolute;
    width: 145px;
    height: 245px;
    right: -30px;
    top: -45px;
    transform: rotate(22.09deg);
}

@include areaGraphics.onExpandMixin('.mask') {
    right: -184px;
    visibility: hidden;
}

.heart {
    @include areaCard.transitionMixin;
    position: absolute;
    width: 48px;
    height: 40.08px;
    right: 64px;
    top: 30px;
}

@include areaGraphics.onExpandMixin('.heart') {
    width: 56px;
    height: 48px;
    right: 94px;
    top: 91px;
}

.hand {
    @include areaCard.transitionMixin;
    position: absolute;
    width: 98px;
    height: 45.96px;
    right: 37px;
    top: 80.08px;
}

@include areaGraphics.onExpandMixin('.hand') {
    right: 75px;
    top: 114px;
}
