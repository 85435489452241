@use 'common' as common;

.container {
    $padding-desktop: 2.75rem;
    $padding-mobile-y: 1rem;
    $whiteGap: 0.5rem;

    @include common.full-width-mixin();

    background-color: var(--a-deepblue-50);
    padding-top: $padding-desktop - $whiteGap;
    padding-bottom: $padding-desktop;

    @media #{common.$mq-screen-mobile} {
        padding-top: $padding-mobile-y - $whiteGap;
        padding-bottom: $padding-mobile-y;
    }
}

// Quick fix for margins when used on the old frontpage type
:global(.page__main-page) .container {
    margin-bottom: -2rem;
}

.header {
    margin-bottom: 1.5rem;

    @media #{common.$mq-screen-mobile} {
        margin-bottom: 1rem;
    }
}

.links {
    @include common.grid-layout-mixin(2);
}
