.alert {
    margin-bottom: 0.75rem;
    border-radius: 0.5rem;
    padding: var(--a-spacing-3);
    display: flex;
    gap: var(--a-spacing-3);
    align-items: center;
}

.white {
    background-color: white;
}

.yellow {
    background-color: #fff5e4;
}

.alertIcon {
    flex-shrink: 0;
    font-size: 1.5rem;
    align-self: flex-start;
    height: var(--a-font-line-height-xlarge);
}
