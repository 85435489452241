@use 'common' as common;

.container {
    @include common.full-width-mixin();
    padding-top: 2rem;
    padding-bottom: 2rem;
    background-color: common.$a-deepblue-50;

    @media #{common.$mq-screen-tablet} {
        padding-left: 2rem;
        padding-right: 2rem;
    }
    @media #{common.$mq-screen-mobile} {
        padding-left: 1rem;
        padding-right: 1rem;
    }
}

.situations {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    padding: 0;

    li {
        display: flex;

        :global(.part__areapage-situation-card) {
            display: flex;
            width: 100%;
        }
    }

    @media #{common.$mq-screen-mobile} {
        @include common.flex-cols-mixin(1, 1rem, 1rem);
    }
    @media #{common.$mq-screen-tablet} {
        @include common.flex-cols-mixin(2, 1.5rem, 1.5rem);
    }
    @media #{common.$mq-screen-desktop} {
        @include common.flex-cols-mixin(2, 1.5rem, 1.5rem);
    }
}

.header {
    margin-bottom: 1rem;
}
