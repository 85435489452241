@use 'common' as common;

.overviewPage {
    display: flex;
    flex-direction: column;
    padding-bottom: 3rem;
}

.content {
    align-self: center;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-top: 2rem;
    max-width: common.$contentMaxWidth;
    width: 100%;

    :global(.region__pageContent) {
        max-width: common.$sectionMaxWidth;
    }

    :global(.icon-container) {
        background-color: var(--a-green-200);
        img {
            height: 50%;
            width: 50%;
        }
    }
}

.productListWrapper,
.filters {
    padding: 1.25rem;
    max-width: common.$sectionMaxWidth;
    background-color: white;

    @media #{common.$mq-screen-mobile} {
        padding: 1rem;
    }
}

.filters {
    padding-bottom: 0;
}

.productListWrapper {
    list-style: none;
    padding-top: 0;
    margin: 0;
}

.allProducts {
    padding-top: 1rem;

    & > li {
        margin-bottom: 1rem;

        &:last-child {
            margin-bottom: 0;
        }

        & > * {
            margin: 0;
        }
    }
}
