@use 'common' as common;

.filterCheckbox {
    background-color: transparent;
    border-radius: 0.25rem;
    box-shadow: var(--default-action-color) inset 0 0 0 1px;
    cursor: pointer;
    display: flex;
    justify-items: center;
    margin: 0.3rem 0.6rem 0.3rem 0;
    padding: 0.4rem;

    .checkbox {
        appearance: none;
        opacity: 0;
        padding: 0;
        position: absolute;
    }

    .label {
        cursor: pointer;
        display: flex;
        font-size: common.px-to-rem(14px);
        line-height: 1.3;
        position: relative;
        user-select: none;

        img {
            height: 1rem;
            width: 1rem;
            margin-right: 0.4rem;
        }
    }

    &:focus-within {
        box-shadow: common.$a-border-focus inset 0 0 0 3px;
    }

    &:hover {
        background-color: common.$a-surface-selected;

        .label {
            color: var(--default-action-color);

            img {
                background-color: common.$a-surface-action;
            }
        }
    }

    &.selected {
        background-color: common.$a-surface-selected;

        &:hover {
            background-color: var(--default-action-color);

            .label {
                color: white;

                img {
                    background-color: transparent;
                    filter: invert(98%) sepia(3%) saturate(538%) hue-rotate(223deg) brightness(119%)
                        contrast(100%);
                }
            }
        }
    }
}
