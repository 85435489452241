@use 'common' as common;

.overviewFilter {
    display: flex;
    flex-direction: column;
}

.filterWrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    list-style-type: none;
    margin-top: var(--a-spacing-2, 0.5rem);
    padding: 0;
}

.filterButton {
    background-color: var(--a-deepblue-50, common.$a-deepblue-50);
    box-shadow: none;

    &:hover {
        background-color: var(--a-deepblue-100, common.$a-deepblue-100);
    }

    &:hover:not(:focus) {
        box-shadow: none;
    }
}
