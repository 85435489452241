@use 'common' as common;

$color: common.$a-deepblue-100;
$separator-attribs: 1px $color solid;

.expandable {
    border-bottom: $separator-attribs;
    border-radius: 0;
    --__ac-expansioncard-border-width: 0;

    &:global(.navds-expansioncard--open) {
        :global(.navds-expansioncard__header) {
            border-radius: common.$border-radius-xlarge;
        }
    }
}

.expandableHeader {
    padding: var(--a-spacing-5);
    margin: 0 -0.5rem -1px -0.5rem;
    width: calc(100% + 1rem);
    border-radius: common.$border-radius-xlarge;

    @media #{common.$mq-screen-mobile} {
        padding: var(--a-spacing-3);
    }

    &:after {
        display: none;
    }

    &:hover,
    &:focus {
        background-color: var(--a-deepblue-100);
        color: common.$a-text-default;
        box-shadow: 0 0 0 0;

        & + :global(.navds-expansioncard__content) {
            box-shadow: 0 0 0 0;
        }

        .illustration {
            & > :first-child:not(:last-child) * {
                fill: common.$a-white;
            }
        }
    }

    :global(.navds-expansioncard__header-content) {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: var(--a-spacing-4);
    }

    :global(.navds-expansioncard__header-button) {
        align-self: center;
        width: 20px;
    }
}

.expandableContent {
    padding: var(--a-spacing-3) var(--a-spacing-3) var(--a-spacing-7);
}

.illustration {
    width: 56px;
    height: 56px;
    flex-shrink: 0;

    @media #{common.$mq-screen-mobile} {
        width: 40px;
        height: 40px;
    }

    & > :first-child:not(:last-child) * {
        fill: $color;
    }
}

.copyLink {
    margin-top: 0;
    margin-bottom: 0.5rem;
}

.loader {
    display: flex;
    flex-direction: column;
    align-items: center;

    :global(.navds-loader) {
        margin-bottom: 1rem;
    }
}

.panelHeader {
    display: flex;
    flex-direction: column;
    font-weight: var(--a-font-weight-bold);
    font-size: var(--a-font-size-heading-small);
    word-break: break-word;
}

.subHeader {
    font-size: 1rem;
    font-weight: normal;
    color: var(--a-gray-700);
}
