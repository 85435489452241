@use 'common' as common;

// Use global CSS rather than module to simplify overriding of styles
// The component this styling applies to is intended for very flexible styling

$padding: 1.5rem;
$blueLinkColor: var(--default-action-color);
$blackLinkColor: common.$a-text-default;

.linkPanelNavno {
    position: relative;

    display: flex;
    align-items: flex-start;

    padding: $padding;
    border-radius: common.$border-radius-xlarge;

    &:hover {
        .linkPanelNavnoLink {
            text-decoration: none;
            color: $blueLinkColor;
        }
    }

    &:focus,
    &:focus-within {
        @include common.panel-focus-mixin(true);
    }
}

.linkPanelNavnoLink {
    color: $blueLinkColor;
    text-decoration-line: underline;
    text-decoration-thickness: 0.05em;
    text-underline-offset: 0.1em;

    @media #{common.$mq-screen-mobile-small} {
        font-size: 1.25rem;
        line-height: 1.75rem;
    }

    &.linkBlack {
        color: $blackLinkColor;
        text-decoration-color: $blackLinkColor;
    }

    &.underline {
        text-decoration-line: underline;
    }

    &:focus {
        outline: none;
    }

    // Stretches the link over the entire parent container
    &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
    }
}

.linkPanelNavnoIngress {
    margin-top: 1rem;
}

.linkPanelNavnoIcon {
    margin-right: 1rem;
}
