@use 'common' as common;

.indexPage {
    margin: 0 auto;
}

.contentBottom {
    display: flex;
    flex-direction: column;
    row-gap: 0.5rem;
    background-color: common.$a-white;

    @include common.full-width-mixin();
}
