@use 'common' as common;

.officePageHeader {
    align-items: center;
    background-color: white;
    border: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-top: 2rem;
    padding-bottom: 1rem;

    @include common.full-width-mixin();

    @media #{common.$mq-screen-mobile} {
        padding-left: 1rem;
        padding-right: 1rem;

        .illustration {
            display: none;
        }
    }
}

.heading {
    text-align: left;
}

.illustration {
    height: 5rem;
    margin-right: 2rem;
    pointer-events: none;
    width: 5rem;
}

.content {
    background-color: white;
    max-width: 56.25rem;
    text-align: center;
    width: 100%;
}

.taglineWrapper {
    display: flex;
    flex-wrap: nowrap;
    align-items: flex-start;

    @media #{common.$mq-screen-mobile} {
        flex-direction: column;
        align-items: flex-start;
        flex-wrap: wrap;
    }
}

.taglineLabel {
    color: var(--a-gray-800);
    text-align: left;
    white-space: nowrap;
    display: flex;
    text-transform: uppercase;

    @media #{common.$mq-screen-mobile} {
        margin-bottom: 0.5rem;
    }
}

.branchNamesLabel {
    color: var(--a-gray-800);
    text-align: left;
    display: flex;
    @media #{common.$mq-screen-mobile} {
        margin-bottom: 0.5rem;
    }

    &::before {
        content: '';
        border-left: 1px solid black;
        display: flex;
        padding: 0 0.75rem 0 0;
        margin-left: 0.75rem;

        @media #{common.$mq-screen-mobile} {
            display: none;
        }
    }
}

.divider,
.modifiedLabel {
    color: var(--a-gray-800);
}
