.videoPage {
    background-color: white;
    display: block;
    max-width: 37.5rem;
    padding: 2.5rem;
    margin: 0 auto;

    // VideoPage is used for previewing only,
    // so we need to do a bit of overriding the
    // actual components
    & > div {
        margin: 0;
    }

    img {
        max-width: 100%;
    }
}
