@use 'common' as common;

.expandable {
    display: block;

    :global(.navds-expansioncard__header-chevron) {
        transform: rotate(0) !important;
    }

    &:global(.navds-expansioncard--open) {
        :global(.navds-expansioncard__header-chevron) {
            transform: rotate(180deg) !important;
        }

        .header {
            position: sticky;
            top: var(--decorator-sticky-offset, 0px);
        }
    }

    .header {
        background-color: #e6f0ff;
        border-radius: 22px;
        border: 0px;
        box-shadow: 0 0 0;
        padding: var(--a-spacing-2) var(--a-spacing-2) var(--a-spacing-2) var(--a-spacing-3);
        width: fit-content;

        :global(.navds-expansioncard__header-content) {
            align-items: center;
            color: var(--default-action-color);
            display: flex;
            gap: var(--a-spacing-2);
        }

        :global(.navds-expansioncard__header-button) {
            min-height: 20px;
            svg {
                path {
                    fill: var(--default-action-color);
                }
            }
        }

        &:hover {
            background-color: #cce1ff;
            box-shadow: 0 0 0;

            & + .content {
                box-shadow: 0 0 0;
            }

            :global(.navds-expansioncard__header-button) {
                background-color: transparent;
            }

            :global(.navds-expansioncard__header-chevron) {
                transform: translateY(0);
            }
        }
        &:after {
            display: none;
        }
    }

    &.legacy {
        .header {
            width: 100%;
            padding-left: var(--a-spacing-4);
        }
    }

    .headerTitle {
        display: flex;
    }

    .headerIcon {
        display: flex;
        width: 28px;
        height: 28px;
    }
    .content {
        border: 0;
        border-left: var(--a-spacing-05) solid var(--a-gray-400);
        margin: var(--a-spacing-2) 0 0 var(--a-spacing-6);
        border-radius: 0;
        padding-left: var(--a-spacing-5);
        box-shadow: 0 0 0;
    }
    :global(.parsedHtml:last-child) {
        margin-bottom: 0;
        > *:last-child {
            margin-bottom: 0;
            > *:last-child {
                margin-bottom: 0;
            }
        }
    }

    :global(.part__html-area):has(.expandable) + :global(.part__html-area):has(.expandable) {
        margin-top: calc(var(--a-spacing-6) * -1);
    }

    :global(:not(:last-child)) {
        & > .expandable {
            margin-bottom: var(--a-spacing-7);
        }
    }

    // Only applicable when a table is inside an expandable. This is
    // why this rule is not inside the DefaultHtmlStyling.module.scss.
    table {
        table-layout: fixed;
    }
}
