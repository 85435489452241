@use 'common' as common;

.banner {
    display: flex;
    justify-content: space-between;
    gap: 1rem;
    width: calc(100% - 0.75rem);

    text-decoration: none;
    color: var(--a-text-default);
    margin-top: 1rem;
    padding: 1.75rem;
    background-color: var(--a-red-50);
    border-radius: 0.5rem;

    transition: background-color 200ms ease-out;

    &:hover {
        background-color: var(--hover-color);
    }

    &:focus {
        @include common.panel-focus-mixin();
    }

    @media #{common.$mq-screen-mobile} {
        padding: 1.5rem 1.25rem;
        transform: none;
        width: 100%;
    }
}

.content {
    display: flex;
    flex-direction: column;
    row-gap: 1.75rem;
    justify-content: center;
    width: 100%;

    > * {
        margin-top: 0 !important;
        margin-bottom: 0 !important;
        font-size: 1.5rem;
        font-weight: var(--a-font-weight-bold);
    }
}

.icon {
    align-self: center;
}
