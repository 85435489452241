.facts_v1 {
    background: #f1f1f1;
    border: 1px solid #d1d1d1;
    padding: 1.5rem;
    margin-top: 4.2rem;

    p {
        margin-bottom: 1rem;
    }

    h2 {
        text-align: center;
    }

    .factIcon {
        width: 1.25rem;
        height: 1.25rem;
        display: flex;
        margin: 0 auto 0.5rem auto;
    }

    .decorated {
        margin-bottom: 1.4rem;

        &:after {
            border-top: 1px solid #808080;
            content: ' ';
            display: block;
            margin: 1.25rem auto 0;
            width: 2rem;
        }
    }
}

.facts_v2 {
    background: var(--a-purple-50);
    padding: 1.5rem;
    margin-top: 2.75rem;
    border-left: 8px solid var(--a-purple-300);

    p {
        margin-bottom: 1rem;
        &:last-of-type {
            margin-bottom: 0;
        }
    }

    h2 {
        color: var(--a-purple-500);
    }

    .decorated {
        margin-bottom: 1.4rem;
    }
}
