@use 'common' as common;

.navnoLenke {
    text-decoration: none;
}

.withChevron {
    display: inline-flex;
    flex-direction: column;
    align-items: flex-start;

    .lenketekst {
        display: inline-flex;
    }

    &:hover {
        .customChevronStyle {
            left: 0.325rem;
        }

        .lenketekst {
            text-decoration-line: underline;
        }
    }
}

.lenketekst {
    display: inline;
    color: var(--default-action-color);
    @include common.link-underline-mixin(false);
}

.label {
    padding-top: 0.3rem;
    padding-left: 1.8rem;
    color: black;
}

.iconContainer {
    min-width: 1.8rem;
}

.customChevronStyle {
    position: relative;
    transition: left 250ms;
    top: 0.0625rem;
    left: 0;
}
