.errorPage {
    display: flex;
    flex-direction: column;
    max-width: 50rem;
    margin: 0 auto;

    padding: 1.5rem;
    background: white;
    border-radius: 0.25rem;

    .header {
        display: flex;
        align-items: center;
    }

    .headerMsg {
        margin-right: 1.5rem;
        padding-right: 1.5rem;
        vertical-align: top;
    }

    .content {
        margin-top: 1.5rem;
    }
}
