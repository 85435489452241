.uxSignalsWidget {
    display: flex;
    flex-direction: column;
    margin: 1rem 0;

    :global(.uxsignals-embed) {
        h2 {
            font-size: var(--a-font-size-heading-large) !important;
            line-height: 1.5 !important;
        }
        p,
        span {
            font-size: var(--a-font-size-large) !important;
            line-height: 1.5 !important;
        }
    }

    &:empty {
        display: none;
    }

    & > div {
        margin: 0;
    }
}
