@use 'common' as common;

.figureContainer {
    text-align: center;

    figure {
        width: 100%;
        margin-top: 4rem;
        display: inline-block;

        img {
            max-width: 100%;
            height: auto;
        }

        &.figureSmall img {
            max-width: 40%;
        }
        &.figureMedium img {
            max-width: 70%;
        }
        &.figureFull img {
            max-width: 100%;
        }

        figcaption {
            font-size: var(--a-font-size-small);
            letter-spacing: 0.004em;
            line-height: var(--a-font-line-height-medium);
            margin: 0.5rem 0;
            text-align: left;
        }
    }
}
