@use 'common' as common;

.card {
    width: 100%;
    position: relative;
    display: flex;

    & > * {
        margin-bottom: 0;
        height: 100%;
    }

    :global(.situation) {
        min-height: 0 !important;
    }
}

.disabled {
    pointer-events: none;
    :global(.situation) {
        filter: grayscale(100%) blur(3px);
    }
}

.disabledMsg {
    position: absolute;
    width: calc(100% - 2rem);
    height: auto;
    margin: 1rem;
    padding: 1rem;
    text-align: center;
    z-index: 1;
    border-radius: 4px;
    background-color: common.$a-orange-100;
}
