@use 'common' as common;

.mainPanels {
    $margin: 1rem;

    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 2.25rem;
    text-align: center;
    width: 100%;

    @media #{common.$mq-screen-mobile} {
        @include common.flex-cols-mixin(1, $margin, $margin);
    }

    @media #{common.$mq-screen-tablet} {
        @include common.flex-cols-mixin(2, $margin, $margin);
    }

    @media #{common.$mq-screen-desktop} {
        @include common.flex-cols-mixin(3, $margin, $margin);
    }

    .item {
        padding: 1.5rem;
    }
}
