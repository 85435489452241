@use '../../AreaCardGraphicsCommon.module' as areaGraphics;
@use '../../../AreaCard.module' as areaCard;

.shapes {
    @include areaCard.transitionMixin;
    position: absolute;
    right: 52px;
    top: 49px;
}

@include areaGraphics.onExpandMixin('.shapes') {
    right: 190px;
    top: 118px;
}

.letterU {
    @include areaCard.transitionMixin;
    position: absolute;
    right: -98px;
    top: 0;
}

@include areaGraphics.onExpandMixin('.letterU') {
    right: 0;
}

.mask {
    background-color: var(--a-deepblue-100);

    @include areaCard.transitionMixin;
    position: absolute;
    width: 145px;
    height: 245px;
    right: -30px;
    top: -45px;
    transform: rotate(22.09deg);
}

@include areaGraphics.onExpandMixin('.mask') {
    right: -184px;
}

.wallet {
    @include areaCard.transitionMixin;
    position: absolute;
    width: 75.73px;
    height: 71px;
    right: 56.27px;
    top: 40px;
}

@include areaGraphics.onExpandMixin('.wallet') {
    right: 98.27px;
    top: 89px;
}
