@use 'common' as common;

.tittel {
    margin-bottom: 0.5rem;
}

.lenker {
    display: flex;
    flex-direction: column;
    @include common.unstyled-list();
}

.lenke {
    $horizontalPadding: 0.5rem;
    $verticalPadding: 0.6875rem;
    padding: $verticalPadding $horizontalPadding;
    margin-top: 0;
    margin-bottom: 0;
    margin-left: -($horizontalPadding);
    margin-right: -($horizontalPadding);

    &:focus,
    &:focus-visible,
    &:active {
        @include common.panel-focus-mixin();
        background-color: transparent;
        color: var(--default-action-color);
    }
}

.ulListe {
    padding-left: 1.4rem;
    margin-bottom: 1.75rem;
    margin-top: 0;
    font-size: 1.125rem;

    li:not(:last-child) {
        margin-bottom: 0.25rem;
    }
}

:global(.part__dynamic-link-list):last-child {
    .ulListe {
        margin-bottom: 0;
    }
}
