.icon {
    align-items: center;
    align-self: flex-start;
    background-color: white;
    border-radius: 2.75rem;
    display: flex;
    flex: 1;
    justify-content: center;
    min-height: 2.75rem;
    min-width: 2.75rem;
    max-height: 2.75rem;
    max-width: 2.75rem;
    margin-top: -7px;

    img {
        width: 25px;
        height: auto;
    }
}

.writeIcon {
    transform: translateX(2px);
}
